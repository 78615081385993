import React, { FC, useCallback, useEffect } from 'react';

import {
  generateLocationQueryString,
  getRemovedParamFromLocationQueryString,
  setUrl,
} from 'utils/browser';
import { getCurrentPageFromUrl } from 'utils/functions';

import { PaginationProps } from './models';

import './Pagination.scss';

const PAGE_URL_PARAM = 'page';
const diffPages = 2;

const Pagination: FC<PaginationProps> = ({ activePage, pageCount, handleActiveListPage }) => {
  const getPaginationContent = (): Array<number | undefined> => {
    const temp: Array<number | undefined> = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= pageCount - 1; i++) {
      const diff = Math.abs(i - activePage) <= diffPages;
      if (diff) {
        temp.push(i);
      } else if (temp.length === 0 || typeof temp[temp.length - 1] !== 'undefined') {
        temp.push(undefined);
      }
    }

    return temp;
  };

  const setPaginationUrlParams = useCallback((currentPage: number): void => {
    let url: string | null = '';
    if (currentPage) {
      url = generateLocationQueryString(PAGE_URL_PARAM, String(currentPage));
    } else {
      url = getRemovedParamFromLocationQueryString(PAGE_URL_PARAM);
    }

    setUrl(url || '');
  }, []);

  const handleChangePage = useCallback(
    (currentPage: number) => () => {
      handleActiveListPage(currentPage);
      setPaginationUrlParams(currentPage);
    },
    [handleActiveListPage, setPaginationUrlParams]
  );

  const prevPageHandler = () => {
    if (activePage > 0)
      handleActiveListPage((prevState) => {
        setPaginationUrlParams(prevState - 1);

        return prevState - 1;
      });
  };

  const nextPageHandler = () => {
    if (activePage < pageCount - 1)
      handleActiveListPage((prevState) => {
        setPaginationUrlParams(prevState + 1);

        return prevState + 1;
      });
  };

  const handleActiveListPageOnLoad = useCallback(() => {
    const currentPage = getCurrentPageFromUrl();

    if (!currentPage) {
      return;
    }

    handleActiveListPage(currentPage);
  }, [handleActiveListPage]);

  useEffect(() => {
    handleActiveListPageOnLoad();
  }, [handleActiveListPageOnLoad]);

  return (
    <div className="container">
      <ul className="pagination">
        <li className="pagination-item">
          <button
            type="button"
            aria-label="pagination-preview-page"
            className="pagination-item__button prev-button"
            onClick={prevPageHandler}
          />
        </li>

        {getPaginationContent().map((item) =>
          typeof item === 'number' ? (
            <li
              className={activePage === item ? 'active pagination-item' : 'pagination-item'}
              key={item}
            >
              <button
                className="pagination-item__button"
                type="button"
                onClick={handleChangePage(item)}
                aria-label="paginationItem"
              >
                {item + 1}
              </button>
            </li>
          ) : (
            <li className="pagination-item">
              <div className="dash" />
            </li>
          )
        )}

        <li className="pagination-item">
          <button
            type="button"
            aria-label="pagination-preview-page"
            className="pagination-item__button next-button"
            onClick={nextPageHandler}
          />
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
