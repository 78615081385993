import React, { FC, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { useScreenRecognition } from '@phx-husky/use-screen-recognition';

import Layout from 'layout/Layout';
import Carousel from 'common/Carousel';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import ArticleCardsList from 'components/ArticleCardsList';
import { LimitDesktop, LimitMobile } from 'components/ArticleCardsList/constants';
import ArticleTagsList from 'components/ArticleTagsList';
import InfoBanner from 'components/InfoBanner';
import Pagination from 'components/Pagination';

import './NewsArticleListingPage.scss';

const NewsArticleListingPage: FC<NewsArticlesListingPageTypes.NewsArticlesListingPageProps> = ({
  data: {
    newsArticlesListingPage: {
      title: newsTitle,
      urls,
      seo,
      mainCarousel,
      infoBanner,
      secondaryTagNavigation,
      tags,
    },
    articles: { nodes: articles },
  },
}) => {
  const { isMobile } = useScreenRecognition();
  const { title, keywords, description } = seo;

  const [currentPage, setCurrentPage] = useState<number>(0);
  const perPage = useMemo(() => (isMobile ? LimitMobile : LimitDesktop), [isMobile]);
  const pageCount = Math.ceil(articles.length / perPage);

  const articlesSlice = useMemo(
    () => articles.slice(currentPage * perPage, currentPage * perPage + perPage),
    [articles, currentPage, perPage]
  );

  return (
    <Layout headerTransparent className="articles-listing">
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : null}
      <div className="container">
        <h1 className="articles-listing__title">{newsTitle}</h1>
      </div>
      <div className="container">
        <ArticleTagsList tags={secondaryTagNavigation} activeTag={tags[0]} />
      </div>
      <ArticleCardsList cards={articlesSlice} />
      {articles.length ? (
        <Pagination
          activePage={currentPage}
          pageCount={pageCount}
          handleActiveListPage={setCurrentPage}
        />
      ) : null}

      <InfoBanner {...{ infoBanner }} />
    </Layout>
  );
};

export const query = graphql`
  query NewsArticlesListingPage(
    $lang: String
    $tags: [Int]
    $link: String = ""
    $sortByDateInputParams: NewsArticlePageSortInput
  ) {
    newsArticlesListingPage(lang: { eq: $lang }, url: { eq: $link }) {
      title
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
      tags {
        title
        name
        id
      }
      secondaryTagNavigation {
        id
        url {
          url
        }
        name
        title
      }
    }
    articles: allNewsArticlePage(
      filter: { tags: { elemMatch: { id: { in: $tags } } } }
      sort: $sortByDateInputParams
    ) {
      nodes {
        url
        createDate
        title
        introListingText
        secondaryTagNavigation {
          id
          url {
            url
          }
          name
          title
        }
        newsArticleBanner {
          structure
          bannerImage {
            ...UmbracoImage
          }
        }
      }
    }
  }
`;

export default NewsArticleListingPage;
