import React, { FC } from 'react';
import dayjs from 'dayjs';

import ArticleCard from 'components/ArticleCard';

import './ArticleCardsList.scss';

const ArticleCardsList: FC<{ cards: NewsArticlePageTypes.NewsArticle[] }> = ({ cards }) => (
  <div className="article-cards-list container" data-test="ArticleCardsList">
    {cards
      .sort((a, b) => +dayjs(b.updateDate) - +dayjs(a.updateDate))
      .map((article) => (
        <ArticleCard key={article.id} article={article} />
      ))}
  </div>
);

export default ArticleCardsList;
