import React, { FC } from 'react';
import { Link } from 'gatsby';
import dayjs from 'dayjs';

import Image from 'common/Image';
import ArticleTagsList from 'components/ArticleTagsList';

import './ArticleCard.scss';

const ArticleCard: FC<{ article: NewsArticlePageTypes.NewsArticle }> = ({ article }) => {
  const banner = article.newsArticleBanner[0]?.bannerImage;

  return (
    <div className="article-card" data-test="RelatedArticleCard">
      {banner ? <Image key={banner.altText} imageData={banner} alt={banner.altText} /> : null}
      <div className="article-card__content">
        <p className="">{dayjs(article.createDate).format('DD MMMM YYYY')}</p>
        <Link
          to={article.url}
          aria-label={article.title}
          className="article-card__title article-link category__link"
        >
          {article.title}
        </Link>
        <p className="article-card__intro-listing-text">{article.introListingText}</p>
        <ArticleTagsList tags={article.secondaryTagNavigation} />
      </div>
    </div>
  );
};

export default ArticleCard;
