import React, { FC } from 'react';
import { Link } from 'gatsby';

import './ArticleTagsList.scss';

const ArticleTagsList: FC<{
  tags: NewsArticlesListingPageTypes.ArticleTag[];
  activeTag?: NewsArticlesListingPageTypes.ArticleTag;
}> = ({ tags, activeTag }) => (
  <ul className="article-tags" data-testid="article-tags-list">
    {tags?.map((tag) => (
      <li key={tag.id} className="article-tag">
        <Link
          to={tag.url[0]?.url}
          aria-label={tag.url[0]?.name}
          className={`article-tag-link category__link ${activeTag?.id === tag.id ? ' active' : ''}`}
        >
          {tag.title}
        </Link>
      </li>
    ))}
  </ul>
);

export default ArticleTagsList;
